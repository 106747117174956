<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Apollo Scooters
            <br>
            Reset your password
          </h2>
        </b-link>

        <h5 v-if="done">
          Password Changed Successfully.
        </h5>

        <!-- form -->
        <validation-observer
          v-if="!done"
          ref="resetForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                vid="password"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="New Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div
              v-if="loading"
              class="d-flex justify-content-center mb-1 mt-5"
            >
              <b-spinner label="Loading..." />
            </div>
            <!-- submit button -->
            <b-button
              v-if="!loading"
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Reset
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // BSV
    BSpinner,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      done: false,
      loading: false,
      password: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.resetForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.$http.post(this.$resources.customers.setNewPassword, {
            token: this.$route.params.token,
            password: this.password,
          })
            .then(response => {
              if (response.data.status === 200) {
                this.done = true
              } else {
                this.$refs.resetForm.setErrors({ password: ['Invalid url, please request new reset url.'] })
              }
            })
            .catch(error => {
              console.log(error)
              this.$refs.resetForm.setErrors(error.response.data.error)
            }).finally(() => this.loading = false)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
